<template>
  <b-form-row>
    <b-col md="6">
      <profile-tabs-data-heading :data="data" />
      <profile-tabs-data-capital :data="data" />
    </b-col>
    <b-col md="6" class="mt-3 mt-md-0">
      <profile-tabs-data-ateco :data="data" />
      <profile-tabs-data-sites :data="data" />
    </b-col>
  </b-form-row>
</template>

<script>
const ProfileTabsDataHeading = () => import('./tabData/ProfileTabsDataHeading.vue');
const ProfileTabsDataCapital = () => import('./tabData/ProfileTabsDataCapital.vue');
const ProfileTabsDataAteco = () => import('./tabData/ProfileTabsDataAteco.vue');
const ProfileTabsDataSites = () => import('./tabData/ProfileTabsDataSites.vue');

export default {
  name: 'ProfileTabsData',
  components: {
    ProfileTabsDataHeading,
    ProfileTabsDataCapital,
    ProfileTabsDataAteco,
    ProfileTabsDataSites,
  },
  props: {
    data: Object,
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>

</style>
